define("discourse/plugins/discourse-audio-message/discourse/initializers/audio-message-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal"], function (_exports, _pluginApi, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (siteSettings.audio_message_enabled) {
      api.modifyClass("component:d-editor", {
        pluginId: 'audio-message',
        actions: {
          openAudioMessageModal() {
            (0, _showModal.default)("audio-message");
          }
        }
      });
      api.onToolbarCreate(tb => {
        tb.addButton({
          id: 'audio-message',
          group: 'extras',
          icon: 'microphone',
          shortcut: 'R',
          sendAction: () => tb.context.send('openAudioMessageModal')
        });
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-audio-message",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => initializeWithApi(api));
    }
  };
});